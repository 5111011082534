import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const StyledContainer = styled(Container)`
    &&& {
        background: white;
        z-index: 1;
    }
`;

const StyledStack = styled(Stack)`
    &&& {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-family: 'Poppins';
        text-align: center;
    }

    @media (max-width: 460px) {
        padding: 0 15px;
    }
`;

const StyledImage = styled(Image)`
    &&& {
        object-fit: contain;
    }

    @media (max-width: 500px) {
        max-width: 100px; /* Adjust this value as needed */
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Adjust padding as needed */

    @media (max-width: 500px) {
        padding: 5px; /* Adjust padding as needed */
    }
`;

export default function Footer() {
    return (
        <StyledContainer fluid>
            <Row className="justify-content-center">
                <Col xs="auto" className="p-3">
                    <ImageContainer>
                        <a href="https://www.bpi.com.ph" target="_blank" rel="noopener noreferrer">
                            <StyledImage
                                src={require('../assets/images/bpilogo.png')}
                                style={{ width: '100%', maxWidth: '150px' }}
                                fluid
                                className=""
                            />
                        </a>
                    </ImageContainer>
                </Col>
                <Col xs="auto" className="p-3">
                    <ImageContainer>
                        <a href="https://bpims.com/" target="_blank" rel="noopener noreferrer">
                            <StyledImage
                                src={require('../assets/images/bpims-logo.png')}
                                style={{ width: '100%', maxWidth: '170px', paddingTop: '18px' }}
                                fluid
                                className=""
                            />
                        </a>
                    </ImageContainer>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="justify-content-center">
                    <StyledStack className="text-center mb-5">
                        <p className="m-0 p-0">Powered by BPI/MS Insurance Corporation, a joint venture between the</p>
                        <p className="m-0 p-0 mb-3">
                            Bank of the Philippine Islands and Mitsui Sumitomo Insurance Corporation of Japan.
                        </p>
                        <p className="m-0 p-0" style={{ fontWeight: 'bold' }}>
                            Policy terms and conditions apply.
                        </p>
                        <p className="m-0 p-0">
                            BPI/MS Insurance Corporation's products are non-life insurance products and are not deposit
                        </p>
                        <p className="m-0 p-0">
                            products of Bank of the Philippine Islands. These products are not covered by the Philippine
                            Deposit
                        </p>
                        <p className="m-0 p-0">Insurance Corporation.</p>
                        <br />
                        <p className="m-0 p-0">
                            For concerns, you may call BPI MS at (02) 8840-9000 or send an e-mail to helpline@bpims.com.
                        </p>
                        <p className="m-0 p-0">Our representatives will be glad to assist you.</p>
                        <br />
                        <p className="m-0 p-0">
                            BPI/MS Insurance Corporation is regulated by the Insurance Commission (IC).
                        </p>
                        <p className="m-0 p-0">For more details, visit https://www.insurance.gov.ph.</p>
                        <br />
                        <p className="m-0 p-0">IC Public Assistance Office contact details:</p>
                        <p className="m-0 p-0">Landline: (02) 8523-8461 loc. 103/127</p>
                        <p className="m-0 p-0">SMS: 09171160007 or 09999930637</p>
                        <p className="m-0 p-0">E-mail: publicassistance@insurance.gov.ph</p>
                        <p className="m-0 p-0">Offices: https://www.insurance.gov.ph/contact-us/</p>
                    </StyledStack>
                </Col>
            </Row>
        </StyledContainer>
    );
}
