import React, { useEffect} from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { trackFacebookEvent, trackFacebookCustomEvent } from '../service/facebookPixel'; 

const StyledContainer = styled(Container)`
    &&& {
        padding: 0;
    }
`;

const StyledRow = styled(Row)`
    &&& {
        padding: 2% 5%;
    }
`;

const StyledCard = styled(Card)`
    &&& {
        height: 100%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        border: unset !important;
        box-shadow: unset !important;
    }

    @media (max-width: 767px) {
        border: unset !important;
        box-shadow: unset !important;
    }
`;

const ContentStack = styled(Stack)`
    &&& {
        padding: 2%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
        gap: 30px !important;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 30px !important;
    }
`;

const TextStack = styled(Stack)`
    justify-content: center;
    padding: 0 15px;

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 10% !important;
    }

    @media (max-width: 767px) {
        padding: 0 10% !important;
    }
`;

const StyledButton = styled.button`
    &&& {
        border-radius: 32px;
        background: rgb(242, 216, 65);
        z-index: 1;
        transition: all 0.2s ease-in 0s;
        padding: 5px 20px;
        border-width: 0;
        max-width: fit-content;
    }
`;

const StyledImage = styled(Image)`
    &&& {
        object-fit: contain;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
`;

export default function AboutUs() {

    useEffect(() => {
        trackFacebookEvent('About Us Page');  // Track the page view on load
    }, []);

    const handleLearnMoreClick = () => {
        trackFacebookCustomEvent('Learn More Button Clicked', { label: 'BPI Debit Mastercard' });  // Track custom event on button click
        window.open('https://www.bpi.com.ph/personal/cards/debit-cards/debit-mastercard', 'bpims');
    };

    return (
        <StyledContainer fluid>
            <StyledRow fluid>
                <Col lg={12} className="justify-content-center">
                    <StyledCard>
                        <Card.Body className="p-0">
                            <ContentStack direction="horizontal" gap={2} justifyContent="space-between">
                                <StyledImage src={require('../assets/images/bpicard.png')} className="mx-auto" />
                                <TextStack style={{ justifyContent: 'center', padding: '0 15px' }}>
                                    <h1 style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'rgba(51, 51,51,1)' }}>
                                        ABOUT US
                                    </h1>
                                    <h2
                                        className="my-2"
                                        style={{ color: 'rgba(51, 51,51,1)', fontFamily: 'Poppins', fontSize: '36px' }}
                                    >
                                        BPI Debit Mastercard®
                                    </h2>
                                    <p style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#565656' }}>
                                        BPI Debit Mastercard® provides convenience like no other while keeping your
                                        transactions safe and secure anywhere you go.
                                    </p>
                                    <StyledButton
                                         onClick={handleLearnMoreClick}
                                     >
                                         LEARN MORE
                                     </StyledButton>
                                </TextStack>
                            </ContentStack>
                        </Card.Body>
                    </StyledCard>
                </Col>
            </StyledRow>
        </StyledContainer>
    );
}
