/* global fbq */

// Check if Facebook Pixel is loaded
const isPixelInitialized = () => typeof fbq !== "undefined";

// Initialize Facebook Pixel
export const initFacebookPixel = (pixelId) => {
    if (!isPixelInitialized()) {
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

        fbq("init", pixelId); // Initialize Pixel with the provided ID
        fbq("track", "PageView"); // Track the initial page view
        console.log("Facebook Pixel initialized with ID:", pixelId);
    }
};

// Track a custom event
export const trackFacebookEvent = (eventName, eventData = {}) => {
    if (isPixelInitialized()) {
        fbq("track", eventName, eventData);
        console.log(`Event tracked: ${eventName}`, eventData);
    } else {
        console.warn("Facebook Pixel not initialized");
    }
};

// // Track a custom event with 'trackCustom'
export const trackFacebookCustomEvent = (eventName, eventData = {}) => {
    if (isPixelInitialized()) {
        fbq("trackCustom", eventName, eventData);
        console.log(`Custom event tracked: ${eventName}`, eventData);
    } else {
        console.warn("Facebook Pixel not initialized");
    }
};
