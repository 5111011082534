import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { trackFacebookEvent} from '../service/facebookPixel'; 

const ErrorModal = ({ show, handleClose, errorMessage }) => {
    
    useEffect(() => {
        if (show && errorMessage) {
            // Track the error modal display event
            trackFacebookEvent('ErrorModalDisplayed', {
                errorMessage,
            });
        }
    }, [show, errorMessage]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Unable to submit form</Modal.Title>
            </Modal.Header>
            <Modal.Body><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
